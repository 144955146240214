import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";

import { appURLs } from "@/app/app-urls.mjs";
import { ItemBox } from "@/game-fortnite/components/ItemBox.jsx";
import overlayState, { randomLoadout } from "@/game-fortnite/overlay-state.mjs";
import useWeaponsFromItems from "@/game-fortnite/utils/use-weapons-from-items.mjs";
import DraggableUnit from "@/shared/DraggableUnit.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const initialPosition = () => css`
  position: absolute;
  left: var(--sp-3);
  top: var(--sp-5);
`;

const ExampleBox = styled("div")`
  background: hsla(var(--shade7-hsl) / 0.8);
  border-radius: var(--br-lg);
  padding: var(--sp-2);

  .loadout {
    display: flex;
    margin-bottom: var(--sp-4);
    gap: var(--sp-1);
  }
`;

export default function Overlay() {
  const draggableRef = useRef();
  const { stateMap } = useSnapshot(overlayState);
  const weapons = useWeaponsFromItems();
  const { t } = useTranslation();
  const randomize = useCallback(() => {
    randomLoadout();
  }, []);

  return (
    <DraggableUnit
      undraggedPosition={initialPosition()}
      draggableAreaRef={draggableRef}
    >
      <div ref={draggableRef}>
        <ExampleBox>
          {Object.entries(stateMap).map(([k, v]) => {
            const { loadout = [] } = v ?? {};
            return (
              <div key={k}>
                {k}
                <div className="loadout">
                  {loadout.map(([id], i) => {
                    const weapon = weapons[id.toLowerCase()];
                    return (
                      <ItemBox
                        key={i}
                        id={weapon.id}
                        name={weapon.displayName}
                        rarity={weapon.rarity.toUpperCase()}
                        src={`${appURLs.CDN}/fortnite/images/${weapon.icon}.webp`}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
          <Button onClick={randomize}>{t("", "Randomize loadout")}</Button>
        </ExampleBox>
      </div>
    </DraggableUnit>
  );
}

export function meta() {
  return {
    title: [null, "Fortnite - Overlay"],
    description: [null, "Fortnite - Overlay"],
  };
}
